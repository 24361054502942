import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import "material-design-icons/iconfont/material-icons.css";
import Facebook from "/src/images/social/facebook.svg";
import Znanylekarz from "/src/images/social/znanylekarz.svg";
import Twojpsycholog from "/src/images/social/twojpsycholog.svg";
import Instagram from "/src/images/social/instagram.svg";
import TikTok from "/src/images/social/tik-tok.svg";
import BottomCta from "./blocks/bottomCTA";

const FooterContainer = tw.div`
bg-three-50 w-full mx-auto grid grid-cols-1 pb-8
`;

const ActualFooter = tw.div`
    p-0 md:p-6 container w-full mx-auto grid lg:grid-cols-3 md:grid-cols-1 lg:divide-x divide-three text-center lg:first:text-left
`;

const FooterSection = tw.div`
p-2 py-6 md:p-6 text-temp-100 flex justify-center items-center
`;

const FooterFinish = tw.div`
    container w-full mx-auto p-6 flex justify-around text-temp-100 text-center text-sm
`;

const FooterLogo = styled.img`
  max-width: 200px;
  place-content: center;
`;

const SocialIcon = styled.div`
  width: 2em;
  fill: #919191;
  &:hover {
    fill: #324376;
  }
`;

const SocialIconStyled = tw(SocialIcon)`
`;

const SocialLink = styled.a`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  //width:100%;
`;

const Footer = () => {
  return (
    <div>
      <BottomCta />
      <FooterContainer>
        <ActualFooter>
          <FooterSection>
            <span tw="first:max-w-sm">
              Jesteśmy zespołem psycholożek i psychologów oraz seksuolożek i
              seksuologów, którzy zajmują się diagnozowaniem i psychoterapią w
              procesie leczenia zaburzeń psychicznych i seksualnych.
            </span>
          </FooterSection>
          <FooterSection>
            <FooterLogo src="/libere_logotyp_2_odbicie.svg" alt="" />
          </FooterSection>
          <FooterSection>
            <SocialLink
              tw="p-0 md:p-6"
              href="https://www.facebook.com/Libere-pomoc-psychologiczna-i-seksuologiczna-103219655298878"
              target="_blank"
            >
              <SocialIconStyled>
                <Facebook />
              </SocialIconStyled>
            </SocialLink>
            <SocialLink
              tw="p-0 md:p-6"
              href="https://www.instagram.com/libere_gabinet/"
              target="_blank"
            >
              <SocialIconStyled>
                <Instagram />
              </SocialIconStyled>
            </SocialLink>
            {/*
            <SocialLink
              tw="p-0 md:p-6"
              href="https://tiktok.com/"
              target="_blank"
            >
              <SocialIconStyled>
                <TikTok />
              </SocialIconStyled>
            </SocialLink>*/}
            {/*<SocialLink
              tw="p-0 md:p-6 cursor-not-allowed"
              href="https://znanylekarz.pl/"
              target="_blank"
              disabled
              title="Dostępne niedługo!"
            >
              <SocialIconStyled>
                <Znanylekarz />
              </SocialIconStyled>
            </SocialLink>
            <SocialLink
              tw="p-0 md:p-6 cursor-not-allowed"
              href="https://twojpsycholog.pl/"
              target="_blank"
              disabled
              title="Dostępne niedługo!"
            >
              <SocialIconStyled>
                <Twojpsycholog />
              </SocialIconStyled>
            </SocialLink>*/}
            {/*<SocialLink
            tw="p-0 md:p-6"
            href="https://discord.com/"
            target="_blank"
          >
            <SocialIconStyled>
              <Discord />
            </SocialIconStyled>
          </SocialLink>*/}
          </FooterSection>
        </ActualFooter>
        <FooterFinish>
          &copy; 2021 Libéré. Wszelakie prawa zastrzeżone.
        </FooterFinish>
      </FooterContainer>
    </div>
  );
};

export default Footer;
