import styled from "@emotion/styled";
import tw from "twin.macro";
import { keyframes } from "@emotion/react";

export const PageSection = styled.div`
  position: relative;
  background: ${(props) => props.background};
`;
export const PageMain = tw.div`
     grid grid-flow-row relative gap-0
`;

export const Enter = keyframes`
  from {
    right: 20em;
  }
  to {
    right: 15.5em;
  }
`;

export const MessageWithBefore = styled.h1`
  /*&::before{
    display: block;
    position: relative;
    content: "";
    border-top: .3rem solid #304175FF;
    width: 15em;
    right: 20em;
    //transition: right 2s ease-out;
    animation: ${Enter} 200ms;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    top: 0.5em;
  }*/
`;

export const WizytaButton = styled.div`
  bottom: 8em;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    display: block;
    position: absolute;
    content: "";
    border-top: 0.3rem solid #cec7b5;
    width: 30em;
    right: 0;
    margin-right: -132em;
    transition: 450ms ease-out;
    top: 0.6em;
  }

  &:hover::before {
    margin-right: -31em;
  }

  @media (max-width: 1024px) {
    &::before {
    }
  }
`;

export const SampleImage = styled.div`
  background: url("./pexels-alex-green-5699456.jpg") no-repeat center center;
  background-size: 150%;
  background-attachment: fixed;
  width: 100%;
  height: 400px;

  @media (max-width: 1024px) {
    background-size: auto 85%;
    width: 100%;
    height: 30vh;
  }
`;

export const CytatWrapper = styled.div`
  padding: 8em 0;
  @media (max-width: 1024px) {
    padding: 4em 0;
  }
`;

export const CytatInset = styled.div`
  max-width: 1024px;
  padding: 4em;

  span {
    margin-top: 2em;
  }

  @media (max-width: 1024px) {
    text-align: justify;
    padding: 2em 1em;
  }
`;

export const Cytat = styled.div`
  width: 85vw;

  ${tw`bg-gray-100 border-b-2 border-two text-xl text-temp-50`}
  height: auto;
  margin-left: 15vw;

  &::before {
    display: block;
    position: relative;
    content: '"';
    font-size: 8em;
    ${tw`font-times`}
    left: -0.4ch;
    top: 0.3ch;
  }

  @media (max-width: 1024px) {
    width: calc(100% - 1.5em);
    margin-left: 1.5em;
  }
`;
