import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { graphql, Link, useStaticQuery } from "gatsby";
import { flatListToHierarchical } from "../utils/utils";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import "hamburgers/dist/hamburgers.min.css";
import DownArrow from "../images/vectors/down-arrow-svgrepo-com.svg";

//bg-gradient-to-b from-gray-200 to-transparent
const HeaderContainer = tw.div`
w-full mx-auto z-20 bg-white  fixed shadow-xl
`;

export const HeaderSpacer = tw.div`
w-full mx-auto p-6 text-2xl md:text-3xl lg:text-5xl mb-12
`;

const HeaderNavigation = tw.div`
w-full container mx-auto flex items-stretch
`;
const HeaderLogo = tw.a`
flex items-center text-two no-underline hover:no-underline font-bold text-2xl md:text-3xl lg:text-5xl
`;
const TopNav = tw.div`
flex w-1/2 justify-start content-center no-underline hover:no-underline  flex-grow pl-12
`;

const TopNavMobile = tw.div`
flex w-1/2 justify-start content-center no-underline hover:no-underline  flex-grow pl-24 h-full p-10
`;

const ActiveLink = styled(Link)`
  &.actv {
    ${tw`text-four`}
    &:hover {
      ${tw`text-two`}
      &::before {
        ${tw`border-t-2 border-two`}
      }
    }

    &::before {
      display: block;
      position: absolute;
      content: "";
      ${tw`border-t-2 border-four`}
      width: 100%;
      right: 0;
      bottom: 0;
    }
  }
`;

const NavButton = tw(ActiveLink)`
 flex items-baseline 
 transition-all text-one no-underline 
 hover:text-four hover:no-underline 
 text-center h-10 p-4 md:h-auto md:p-4 
 font-bold text-base md:text-base 
 lg:text-xl font-medium uppercase
`;

const NavButtonNoNavigation = tw.div`
 flex items-baseline 
 transition-all text-one no-underline 
 hover:text-four hover:no-underline 
 text-center h-10 p-4 md:h-auto md:p-4 
 font-bold text-base md:text-base 
 lg:text-xl font-medium uppercase
`;

const DropDownButtons = tw(NavButton)`
relative z-10 hidden group-hover:block text-left
`;
const DropDownButtonsStyled = styled(DropDownButtons)`
  display: ${(props) => (props.submenu ? "block" : "none")};
`;

const LineAnim = keyframes`
  0% {
    transform: translate(-20%);
  }
  100% {
    transform: translate(0%);
  }
`;

const LowerLine = styled.div`
  position: relative;
  transition: all 125ms ease-in-out;
  //border: 1px solid red;
  //margin: 3vh 0;
  padding: 0.75em 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    transform: rotate(0deg);
    transition: all 50ms ease-in;
  }

  &:hover {
    transition: all 125ms ease-in-out;
    svg {
      transform: rotate(90deg);
      transition: all 50ms ease-out;
    }

    &::before {
      display: block;
      position: absolute;
      content: "";
      ${tw`border-t-2 border-two`}
      width: 100%;
      right: 0;
      bottom: 0;
      animation: ${LineAnim} 150ms cubic-bezier(0.33, -0.87, 0.37, 1.62);
    }
  }
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
  //margin: 0.5em 0;
  max-width: 200px;
`;

const Header = () => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isClickedMenuItem, setIsClickedMenuItem] = useState("");

  const data = flatListToHierarchical(
    useStaticQuery(graphql`
      query AllMenuItems {
        allWpMenuItem(
          filter: { menu: { node: { slug: { eq: "main-menu" } } } }
        ) {
          nodes {
            title
            label
            id
            parentId
            url
            order
          }
        }
      }
    `).allWpMenuItem.nodes
  );

  return (
    <HeaderContainer>
      <HeaderNavigation>
        <HeaderLogo href="/">
          <Logo tw="flex pl-6 md:hidden" src="/libere_logotyp_2.svg" />
          {/*<Logo tw="hidden md:flex pt-6" src="/libere_logotyp_2_odbicie.svg" />*/}
          <Logo tw="hidden md:flex" src="/libere_logo_test.svg" />
        </HeaderLogo>
        <div tw="flex content-between w-full items-center">
          <TopNav tw="flex-grow hidden md:flex h-full items-center justify-end">
            {data.map((menu) => {
              return menu.children.length !== 0 ? (
                <LowerLine key={menu.url} className="group" tw="h-full">
                  <NavButton
                    partiallyActive={true}
                    to={menu.url}
                    className="group"
                    activeClassName="actv"
                  >
                    <div>{menu.label}</div>
                    {menu.children && <DownArrow tw="fill-current h-4 px-2" />}
                  </NavButton>
                  <div className="absolute w-max bg-white  border-b-2 border-four top-full">
                    {menu.children &&
                      menu.children.map((child) => {
                        return (
                          <LowerLine key={child.url} tw="h-full">
                            <DropDownButtonsStyled
                              key={child.url}
                              to={child.url}
                            >
                              {child.label}
                            </DropDownButtonsStyled>
                          </LowerLine>
                        );
                      })}
                  </div>
                </LowerLine>
              ) : (
                <LowerLine key={menu.url} tw="h-full">
                  <NavButton to={menu.url} activeClassName="actv">
                    {menu.label}
                  </NavButton>
                </LowerLine>
              );
            })}
          </TopNav>

          <TopNavMobile tw="flex-grow lg:hidden">
            <span
              tw="focus:outline-none absolute right-2 z-10 top-1/2 transform -translate-y-1/2"
              onClick={() => {
                setIsClickedMenuItem("");
                setIsOpenMobileMenu(!isOpenMobileMenu);
              }}
              onKeyDown={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
              className="outline-none"
              role="button"
              tabIndex={0}
            >
              <span
                tw="focus:outline-none"
                className={`hamburger hamburger--collapse ${
                  isOpenMobileMenu && "is-active"
                } outline-none`}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </span>
            </span>
            {isOpenMobileMenu && (
              <div tw="h-screen w-screen fixed z-0 bg-white left-0 top-0 p-12 text-center">
                {data.map((menu) => {
                  return menu.children.length !== 0 ? (
                    <LowerLine key={menu.url} className="group">
                      {isClickedMenuItem === menu.url ? (
                        <NavButton
                          partiallyActive={true}
                          to={menu.url}
                          className="group"
                          activeClassName="actv"
                        >
                          {menu.label}
                        </NavButton>
                      ) : (
                        <NavButtonNoNavigation
                          onClick={() => {
                            setIsClickedMenuItem(menu.url);
                          }}
                        >
                          {menu.label}
                        </NavButtonNoNavigation>
                      )}
                      {isClickedMenuItem === menu.url && (
                        <div tw=" absolute w-full bg-two-1 top-16 border-b-2 border-t-2 border-four z-20">
                          {menu.children &&
                            menu.children.map((child) => {
                              return (
                                <LowerLine
                                  key={child.url}
                                  className="w-full bg-two-1"
                                >
                                  <DropDownButtonsStyled
                                    key={child.url}
                                    to={child.url}
                                    submenu={isClickedMenuItem === menu.url}
                                  >
                                    {child.label}
                                  </DropDownButtonsStyled>
                                </LowerLine>
                              );
                            })}
                        </div>
                      )}
                    </LowerLine>
                  ) : (
                    <LowerLine key={menu.url}>
                      <NavButton to={menu.url} activeClassName="actv">
                        {menu.label}
                      </NavButton>
                    </LowerLine>
                  );
                })}
              </div>
            )}
          </TopNavMobile>
        </div>
      </HeaderNavigation>
    </HeaderContainer>
  );
};

export default Header;
