import React from "react";
import Header, { HeaderSpacer } from "./header";
import Footer from "./footer";
import tw, { GlobalStyles } from "twin.macro";
import { Helmet } from "react-helmet";
import { css, Global } from "@emotion/react";
import { PageMain } from "../styles/index/_components";
import styled from "@emotion/styled";

const Main = tw.div`
min-h-screen bg-right bg-cover flex flex-col flex-auto justify-between overflow-x-hidden font-sans text-temp
`;
export const PageWrapper = styled.div`
  ${tw`mb-auto mx-auto  text-xl h-full flex flex-col p-4 sm:p-6 lg:p-20`}
  ${(props) => (props.tight ? tw`max-w-screen-xl` : tw`container w-full`)}
`;

export const PageContent = tw.div`
mb-auto h-10 w-full mx-auto p-6 text-xl
`;

const Layout = ({ children, unwrapped = true }) => {
  return (
    <Main>
      <Global
        styles={css`
          :root {
            --my-main: #000ccc;
          }
        `}
      />
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <script src="https://www.google.com/recaptcha/api.js" />
      </Helmet>
      <GlobalStyles />
      <Header />
      {unwrapped && <HeaderSpacer />}
      <PageMain>{children}</PageMain>
      <Footer />
    </Main>
  );
};

export default Layout;
